import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: theme.spacing(48) },
  color: { fill: theme.palette.grey[500] },
}))

const MockWireframeFig: React.FC = (props) => {
  const classes = useStyles(props)
  return(

<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 446.31 854"><path className={classes.color} d="M394.67,6.29A42.48,42.48,0,0,1,437.1,48.71V805.28a42.48,42.48,0,0,1-42.44,42.43h-343A42.48,42.48,0,0,1,9.22,805.28V48.71A42.47,42.47,0,0,1,51.64,6.29Zm0-1.2h-343A43.67,43.67,0,0,0,8,48.71V805.28a43.68,43.68,0,0,0,43.64,43.63h343a43.68,43.68,0,0,0,43.64-43.63V48.71A43.67,43.67,0,0,0,394.67,5.09Z"/>
<path className={classes.color} d="M394.66,854h-343A48.78,48.78,0,0,1,2.93,805.28V48.71A48.76,48.76,0,0,1,51.64,0h343a48.77,48.77,0,0,1,48.72,48.71V805.28A48.78,48.78,0,0,1,394.66,854ZM51.64,2A46.76,46.76,0,0,0,4.93,48.71V805.28A46.77,46.77,0,0,0,51.65,852h343a46.78,46.78,0,0,0,46.73-46.72V48.71A46.77,46.77,0,0,0,394.67,2Z"/>
<path className={classes.color} d="M260.48,791.14a10.2,10.2,0,0,1,10.19,10.2v15.07a10.19,10.19,0,0,1-10.19,10.19H185.84a10.2,10.2,0,0,1-10.2-10.19V801.34a10.2,10.2,0,0,1,10.2-10.2Zm0-1.2H185.84a11.41,11.41,0,0,0-11.4,11.4v15.07a11.41,11.41,0,0,0,11.4,11.39h74.64a11.4,11.4,0,0,0,11.39-11.39V801.34a11.41,11.41,0,0,0-11.39-11.4Z"/>
<path className={classes.color} d="M260.48,824.36H185.84a8,8,0,0,1-8-8V801.34a8,8,0,0,1,8-8h74.64a8,8,0,0,1,8,8v15.07A8,8,0,0,1,260.48,824.36Zm-74.64-29.78a6.76,6.76,0,0,0-6.76,6.76v15.07a6.76,6.76,0,0,0,6.76,6.75h74.64a6.77,6.77,0,0,0,6.76-6.75V801.34a6.77,6.77,0,0,0-6.76-6.76Z"/>
<path className={classes.color} d="M92,36.26a7.13,7.13,0,1,1-7.13,7.13A7.13,7.13,0,0,1,92,36.26Zm0-1.2a8.33,8.33,0,1,0,8.33,8.33A8.34,8.34,0,0,0,92,35.06Z"/>
<path className={classes.color} d="M92,48.49a5.11,5.11,0,1,1,5.1-5.1A5.11,5.11,0,0,1,92,48.49Zm0-9a3.91,3.91,0,1,0,3.9,3.91A3.91,3.91,0,0,0,92,39.48Z"/>
<path className={classes.color} d="M270.11,37a6.42,6.42,0,0,1,0,12.83h-93.9a6.42,6.42,0,0,1,0-12.83Zm0-1.2h-93.9a7.62,7.62,0,0,0,0,15.23h93.9a7.62,7.62,0,0,0,0-15.23Z"/>
<path className={classes.color} d="M270.11,48.9h-93.9a5.52,5.52,0,0,1,0-11h93.9a5.52,5.52,0,0,1,0,11Zm-93.9-9.83a4.32,4.32,0,0,0,0,8.63h93.9a4.32,4.32,0,0,0,0-8.63Z"/>
<path className={classes.color} d="M177.85,43.39a1.65,1.65,0,1,1-1.64-1.65A1.65,1.65,0,0,1,177.85,43.39Z"/>
<path className={classes.color} d="M183.72,43.39a1.65,1.65,0,1,1-1.64-1.65A1.65,1.65,0,0,1,183.72,43.39Z"/>
<path className={classes.color} d="M189.59,43.39a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,189.59,43.39Z"/>
<path className={classes.color} d="M195.46,43.39a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,195.46,43.39Z"/>
<path className={classes.color} d="M201.33,43.39a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,201.33,43.39Z"/>
<path className={classes.color} d="M207.2,43.39a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,207.2,43.39Z"/>
<path className={classes.color} d="M213.06,43.39a1.65,1.65,0,1,1-1.64-1.65A1.64,1.64,0,0,1,213.06,43.39Z"/>
<path className={classes.color} d="M218.93,43.39a1.65,1.65,0,1,1-1.64-1.65A1.64,1.64,0,0,1,218.93,43.39Z"/>
<path className={classes.color} d="M224.8,43.39a1.65,1.65,0,1,1-1.64-1.65A1.65,1.65,0,0,1,224.8,43.39Z"/>
<path className={classes.color} d="M230.67,43.39A1.65,1.65,0,1,1,229,41.74,1.65,1.65,0,0,1,230.67,43.39Z"/>
<path className={classes.color} d="M236.54,43.39a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,236.54,43.39Z"/>
<path className={classes.color} d="M242.41,43.39a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,242.41,43.39Z"/>
<path className={classes.color} d="M248.28,43.39a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,248.28,43.39Z"/>
<path className={classes.color} d="M254.15,43.39a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,254.15,43.39Z"/>
<path className={classes.color} d="M260,43.39a1.64,1.64,0,1,1-1.64-1.65A1.64,1.64,0,0,1,260,43.39Z"/>
<path className={classes.color} d="M265.88,43.39a1.65,1.65,0,1,1-1.64-1.65A1.64,1.64,0,0,1,265.88,43.39Z"/>
<path className={classes.color} d="M271.75,43.39a1.65,1.65,0,1,1-1.64-1.65A1.65,1.65,0,0,1,271.75,43.39Z"/>
<path className={classes.color} d="M425.45,775.36H20.86V78.64H425.45Zm-402.59-2H423.45V80.64H22.86Z"/>
<path className={classes.color} d="M356.72,38.18a5.21,5.21,0,1,1-5.2,5.21,5.2,5.2,0,0,1,5.2-5.21Zm0-1.2a6.41,6.41,0,1,0,6.4,6.41,6.41,6.41,0,0,0-6.4-6.41Z"/>
<path className={classes.color} d="M288.34,40.4a3,3,0,1,1-3,3,3,3,0,0,1,3-3Zm0-1.2a4.19,4.19,0,1,0,4.18,4.19,4.2,4.2,0,0,0-4.18-4.19Z"/>
<path className={classes.color} d="M299.34,40.4a3,3,0,1,1-3,3,3,3,0,0,1,3-3Zm0-1.2a4.19,4.19,0,1,0,4.18,4.19,4.2,4.2,0,0,0-4.18-4.19Z"/>
<path className={classes.color} d="M4,195.07H1.81A1.81,1.81,0,0,1,0,193.26V126a1.81,1.81,0,0,1,1.81-1.81H4v1.2H1.81a.61.61,0,0,0-.61.61v67.24a.61.61,0,0,0,.61.61H4Z"/>
<path className={classes.color} d="M442.28,181h2.22a1.81,1.81,0,0,1,1.81,1.81V250a1.81,1.81,0,0,1-1.81,1.81h-2.22v-1.2h2.22a.61.61,0,0,0,.61-.61V182.76a.61.61,0,0,0-.61-.61h-2.22Z"/>
<path className={classes.color} d="M4,309.15H1.81A1.81,1.81,0,0,1,0,307.34V240.09a1.82,1.82,0,0,1,1.81-1.81H4v1.2H1.81a.61.61,0,0,0-.61.61v67.25a.61.61,0,0,0,.61.61H4Z"/>
</svg>
  )

}

export default MockWireframeFig 
