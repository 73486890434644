import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: theme.spacing(48) },
  default: { fill: theme.palette.grey[500] },
  primary: { fill: theme.palette.primary.main },
  secondary: { fill: theme.palette.secondary.main },
}))

const InteractiveGraphFig: React.FC = (props) => {
  const classes = useStyles(props)
  return(
      <div  className={classes.root}>

<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 84"><path className={classes.default} d="M3.26,42.89a3.35,3.35,0,0,1-.72.22c-.17,0-.32-.2-.32-.45s.08-.25.31-.3a5.72,5.72,0,0,0,1.26-.45l-.13-.12c-.2-.16-.25-.23-.25-.33a.42.42,0,0,1,.4-.37c.15,0,.28.09.6.44a2.86,2.86,0,0,0,.7-.68s0,0-.05,0H3.89a3.16,3.16,0,0,1-1.27.78.4.4,0,0,1-.33-.42c0-.15,0-.21.21-.29a3.64,3.64,0,0,0,1.24-1c.13-.16.2-.21.31-.21a.43.43,0,0,1,.42.37.58.58,0,0,1,0,.1H5.73c.28,0,.43.11.43.31a1.26,1.26,0,0,1-.41.76,5.2,5.2,0,0,1-1.07.94H6.1c.36,0,.47.11.47.47v1.15c0,.37-.11.47-.47.47H3.73c-.36,0-.47-.1-.47-.47Zm.86,0c-.08,0-.11,0-.11.11v.58c0,.08,0,.11.11.11H5.71c.08,0,.11,0,.11-.11V43c0-.08,0-.11-.11-.11Z"/>
<path className={classes.default} d="M9.1,40.42v-.36c0-.2.14-.31.39-.31s.39.11.39.31v.36h1.65c.18,0,.28.13.28.36s-.1.35-.28.35H9.88v.74h.9c.45,0,.56.1.56.56v1.3c0,.46-.11.56-.56.56H8.22c-.45,0-.56-.1-.56-.56v-1.3c0-.46.11-.56.56-.56H9.1v-.74H7.48c-.19,0-.28-.12-.28-.35s.09-.36.28-.36Zm-.57,2.12c-.09,0-.11,0-.11.11v.85c0,.08,0,.1.11.1h1.94c.09,0,.11,0,.11-.1v-.85c0-.09,0-.11-.11-.11Z"/>
<path className={classes.default} d="M13.22,41.29v.19a8.88,8.88,0,0,1-.27,2.4c-.09.3-.21.41-.39.41a.43.43,0,0,1-.44-.37c0-.08,0-.12.15-.54a6.63,6.63,0,0,0,.2-1.9v-1c0-.43.12-.54.58-.54h3.14c.37,0,.47.1.47.47v.46c0,.36-.1.46-.47.46Zm2.9.7c.15.15.38.43.38.57a.34.34,0,0,1-.33.3c-.12,0-.14,0-.27-.16l-.58,0v.19h1c.15,0,.23.11.23.29s-.08.29-.23.29h-1v.19h1.26c.16,0,.25.1.25.3s-.09.32-.25.32H13.29c-.16,0-.25-.12-.25-.32s.09-.3.25-.3h1.3v-.19h-1c-.14,0-.23-.1-.23-.29s.09-.29.23-.29h1v-.15l-.95,0c-.23,0-.34-.1-.34-.32s.22-.3.4-.3l.15-.21h-.33c-.15,0-.23-.11-.23-.29s.08-.3.23-.3h2.92c.15,0,.23.11.23.3s-.08.29-.23.29Zm-2.9-1.26h2.67c.08,0,.08,0,.08-.15s0-.08-.08-.08H13.3c-.06,0-.08,0-.08.08ZM14.59,42l-.15.22,1.06,0c0-.08,0-.13.1-.2Z"/>
<path className={classes.default} d="M19.11,40.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2h1.67c.17,0,.27.12.27.34s-.09.35-.27.35H19.89v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36h.19c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V42h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V41.89c0-.46.1-.56.55-.56h.94V41H17.45c-.18,0-.27-.12-.27-.34s.09-.35.27-.35Z"/>
<path className={classes.default} d="M67.26,18.89a3.35,3.35,0,0,1-.72.22c-.17,0-.32-.2-.32-.45s.08-.25.31-.3a5.72,5.72,0,0,0,1.26-.45l-.13-.12c-.2-.16-.25-.23-.25-.33a.42.42,0,0,1,.4-.37c.15,0,.28.09.6.44a2.86,2.86,0,0,0,.7-.68s0,0,0,0H67.89a3.16,3.16,0,0,1-1.27.78.4.4,0,0,1-.33-.42c0-.15,0-.21.21-.29a3.64,3.64,0,0,0,1.24-1c.13-.16.2-.21.31-.21a.43.43,0,0,1,.42.37.58.58,0,0,1,0,.1h1.28c.28,0,.43.11.43.31a1.26,1.26,0,0,1-.41.76,5.2,5.2,0,0,1-1.07.94H70.1c.36,0,.47.11.47.47v1.15c0,.37-.11.47-.47.47H67.73c-.36,0-.47-.1-.47-.47Zm.86-.05c-.08,0-.11,0-.11.11v.58c0,.08,0,.11.11.11h1.59c.08,0,.11,0,.11-.11V19c0-.08,0-.11-.11-.11Z"/>
<path className={classes.default} d="M73.1,16.42v-.36c0-.2.14-.31.39-.31s.39.11.39.31v.36h1.65c.18,0,.28.13.28.36s-.1.35-.28.35H73.88v.74h.9c.45,0,.56.1.56.56v1.3c0,.46-.11.56-.56.56H72.22c-.45,0-.56-.1-.56-.56v-1.3c0-.46.11-.56.56-.56h.88v-.74H71.48c-.19,0-.28-.12-.28-.35s.09-.36.28-.36Zm-.57,2.12c-.09,0-.11,0-.11.11v.85c0,.08,0,.1.11.1h1.94c.09,0,.11,0,.11-.1v-.85c0-.09,0-.11-.11-.11Z"/>
<path className={classes.default} d="M77.22,17.29v.19a8.88,8.88,0,0,1-.27,2.4c-.09.3-.21.41-.39.41a.43.43,0,0,1-.44-.37c0-.08,0-.12.15-.54a6.63,6.63,0,0,0,.2-1.9v-1c0-.43.12-.54.58-.54h3.14c.37,0,.47.1.47.47v.46c0,.36-.1.46-.47.46Zm2.9.7c.15.15.38.43.38.57a.34.34,0,0,1-.33.3c-.12,0-.14,0-.27-.16l-.58,0v.19h1c.15,0,.23.11.23.29s-.08.29-.23.29h-1v.19h1.26c.16,0,.25.1.25.3s-.09.32-.25.32H77.29c-.16,0-.25-.12-.25-.32s.09-.3.25-.3h1.3v-.19h-1c-.14,0-.23-.1-.23-.29s.09-.29.23-.29h1v-.15l-1,0c-.23,0-.34-.1-.34-.32s.22-.3.4-.3l.15-.21h-.33c-.15,0-.23-.11-.23-.29s.08-.3.23-.3h2.92c.15,0,.23.11.23.3s-.08.29-.23.29Zm-2.9-1.26h2.67c.08,0,.08,0,.08-.15s0-.08-.08-.08H77.3c-.06,0-.08,0-.08.08ZM78.59,18l-.15.22,1.06,0c0-.08,0-.13.1-.2Z"/>
<path className={classes.default} d="M83.11,16.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2h1.67c.17,0,.27.12.27.34s-.09.35-.27.35H83.89v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36h.19c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V18h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V17.89c0-.46.1-.56.55-.56h.94V17H81.45c-.18,0-.27-.12-.27-.34s.09-.35.27-.35Z"/>
<path className={classes.default} d="M35.26,54.89a3.35,3.35,0,0,1-.72.22c-.17,0-.32-.2-.32-.45s.08-.25.31-.3a5.72,5.72,0,0,0,1.26-.45l-.13-.12c-.2-.16-.25-.23-.25-.33a.42.42,0,0,1,.4-.37c.15,0,.28.09.6.44a2.86,2.86,0,0,0,.7-.68s0,0,0,0H35.89a3.16,3.16,0,0,1-1.27.78.4.4,0,0,1-.33-.42c0-.15,0-.21.21-.29a3.64,3.64,0,0,0,1.24-1c.13-.16.2-.21.31-.21a.43.43,0,0,1,.42.37.58.58,0,0,1,0,.1h1.28c.28,0,.43.11.43.31a1.26,1.26,0,0,1-.41.76,5.2,5.2,0,0,1-1.07.94H38.1c.36,0,.47.11.47.47v1.15c0,.37-.11.47-.47.47H35.73c-.36,0-.47-.1-.47-.47Zm.86,0c-.08,0-.11,0-.11.11v.58c0,.08,0,.11.11.11h1.59c.08,0,.11,0,.11-.11V55c0-.08,0-.11-.11-.11Z"/>
<path className={classes.default} d="M41.1,52.42v-.36c0-.2.14-.31.39-.31s.39.11.39.31v.36h1.65c.18,0,.28.13.28.36s-.1.35-.28.35H41.88v.74h.9c.45,0,.56.1.56.56v1.3c0,.46-.11.56-.56.56H40.22c-.45,0-.56-.1-.56-.56v-1.3c0-.46.11-.56.56-.56h.88v-.74H39.48c-.19,0-.28-.12-.28-.35s.09-.36.28-.36Zm-.57,2.12c-.09,0-.11,0-.11.11v.85c0,.08,0,.1.11.1h1.94c.09,0,.11,0,.11-.1v-.85c0-.09,0-.11-.11-.11Z"/>
<path className={classes.default} d="M45.22,53.29v.19a8.88,8.88,0,0,1-.27,2.4c-.09.3-.21.41-.39.41a.43.43,0,0,1-.44-.37c0-.08,0-.12.15-.54a6.63,6.63,0,0,0,.2-1.9v-1c0-.43.12-.54.58-.54h3.14c.37,0,.47.1.47.47v.46c0,.36-.1.46-.47.46Zm2.9.7c.15.15.38.43.38.57a.34.34,0,0,1-.33.3c-.12,0-.14,0-.27-.16l-.58,0v.19h1c.15,0,.23.11.23.29s-.08.29-.23.29h-1v.19h1.26c.16,0,.25.1.25.3s-.09.32-.25.32H45.29c-.16,0-.25-.12-.25-.32s.09-.3.25-.3h1.3v-.19h-1c-.14,0-.23-.1-.23-.29s.09-.29.23-.29h1v-.15l-1,0c-.23,0-.34-.1-.34-.32s.22-.3.4-.3l.15-.21h-.33c-.15,0-.23-.11-.23-.29s.08-.3.23-.3h2.92c.15,0,.23.11.23.3s-.08.29-.23.29Zm-2.9-1.26h2.67c.08,0,.08,0,.08-.15s0-.08-.08-.08H45.3c-.06,0-.08,0-.08.08ZM46.59,54l-.15.22,1.06,0c0-.08,0-.13.1-.2Z"/>
<path className={classes.default} d="M51.11,52.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2h1.67c.17,0,.27.12.27.34s-.09.35-.27.35H51.89v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36h.19c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V54h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V53.89c0-.46.1-.56.55-.56h.94V53H49.45c-.18,0-.27-.12-.27-.34s.09-.35.27-.35Z"/>
<path className={classes.default} d="M67.26,66.89a3.35,3.35,0,0,1-.72.22c-.17,0-.32-.2-.32-.45s.08-.25.31-.3a5.72,5.72,0,0,0,1.26-.45l-.13-.12c-.2-.16-.25-.23-.25-.33a.42.42,0,0,1,.4-.37c.15,0,.28.09.6.44a2.86,2.86,0,0,0,.7-.68s0,0,0,0H67.89a3.16,3.16,0,0,1-1.27.78.4.4,0,0,1-.33-.42c0-.15,0-.21.21-.29a3.64,3.64,0,0,0,1.24-1c.13-.16.2-.21.31-.21a.43.43,0,0,1,.42.37.58.58,0,0,1,0,.1h1.28c.28,0,.43.11.43.31a1.26,1.26,0,0,1-.41.76,5.2,5.2,0,0,1-1.07.94H70.1c.36,0,.47.11.47.47v1.15c0,.37-.11.47-.47.47H67.73c-.36,0-.47-.1-.47-.47Zm.86,0c-.08,0-.11,0-.11.11v.58c0,.08,0,.11.11.11h1.59c.08,0,.11,0,.11-.11V67c0-.08,0-.11-.11-.11Z"/>
<path className={classes.default} d="M73.1,64.42v-.36c0-.2.14-.31.39-.31s.39.11.39.31v.36h1.65c.18,0,.28.13.28.36s-.1.35-.28.35H73.88v.74h.9c.45,0,.56.1.56.56v1.3c0,.46-.11.56-.56.56H72.22c-.45,0-.56-.1-.56-.56v-1.3c0-.46.11-.56.56-.56h.88v-.74H71.48c-.19,0-.28-.12-.28-.35s.09-.36.28-.36Zm-.57,2.12c-.09,0-.11,0-.11.11v.85c0,.08,0,.1.11.1h1.94c.09,0,.11,0,.11-.1v-.85c0-.09,0-.11-.11-.11Z"/>
<path className={classes.default} d="M77.22,65.29v.19a8.88,8.88,0,0,1-.27,2.4c-.09.3-.21.41-.39.41a.43.43,0,0,1-.44-.37c0-.08,0-.12.15-.54a6.63,6.63,0,0,0,.2-1.9v-1c0-.43.12-.54.58-.54h3.14c.37,0,.47.1.47.47v.46c0,.36-.1.46-.47.46Zm2.9.7c.15.15.38.43.38.57a.34.34,0,0,1-.33.3c-.12,0-.14,0-.27-.16l-.58,0v.19h1c.15,0,.23.11.23.29s-.08.29-.23.29h-1v.19h1.26c.16,0,.25.1.25.3s-.09.32-.25.32H77.29c-.16,0-.25-.12-.25-.32s.09-.3.25-.3h1.3v-.19h-1c-.14,0-.23-.1-.23-.29s.09-.29.23-.29h1v-.15l-1,0c-.23,0-.34-.1-.34-.32s.22-.3.4-.3l.15-.21h-.33c-.15,0-.23-.11-.23-.29s.08-.3.23-.3h2.92c.15,0,.23.11.23.3s-.08.29-.23.29Zm-2.9-1.26h2.67c.08,0,.08,0,.08-.15s0-.08-.08-.08H77.3c-.06,0-.08,0-.08.08ZM78.59,66l-.15.22,1.06,0c0-.08,0-.13.1-.2Z"/>
<path className={classes.default} d="M83.11,64.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2h1.67c.17,0,.27.12.27.34s-.09.35-.27.35H83.89v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36h.19c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V66h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V65.89c0-.46.1-.56.55-.56h.94V65H81.45c-.18,0-.27-.12-.27-.34s.09-.35.27-.35Z"/>
<path className={classes.default} d="M72.81,43.07c0,.39-.17.42-.49.42,0,.06,0,.14-.06.21h.87c.15,0,.23.1.23.28s-.08.3-.23.3H69c-.15,0-.23-.11-.23-.3s.08-.28.23-.28h.82a.85.85,0,0,0-.07-.21c-.31,0-.44-.07-.44-.42v-.22c0-.32.1-.42.42-.42h2.63c.33,0,.43.1.43.42Zm-3.74-.79c-.14,0-.21-.1-.21-.26s.07-.26.21-.26h4c.13,0,.2.09.2.26s-.07.26-.2.26ZM70.2,40c0-.26.23-.26.32-.26s.33,0,.33.26h.43c0-.18.11-.26.33-.26s.32.08.33.26h.63c.37,0,.47.1.47.47v.63c0,.37-.1.47-.47.47h-3c-.37,0-.47-.1-.47-.47v-.63c0-.37.1-.47.47-.47Zm0,.62v-.18h-.32c-.11,0-.11,0-.11.18Zm0,.35h-.43c0,.16,0,.18.11.18h.32Zm-.1,1.88c-.08,0-.08,0-.08.12s0,.08.08.08H72c.08,0,.08,0,.08-.12s0-.08-.08-.08Zm1.44.83.08-.21H70.51a1.29,1.29,0,0,1,.07.21Zm-.26-3.06v-.18h-.43v.18Zm0,.35h-.43v.18h.43Zm.66-.35h.43c0-.16,0-.18-.1-.18h-.33Zm0,.35v.18h.33c.1,0,.1,0,.1-.18Z"/>
<path className={classes.default} d="M73.88,40.48c0-.39.11-.5.5-.5h3.2c.4,0,.51.11.51.5v3.19c0,.39-.11.51-.51.51h-3.2c-.39,0-.5-.12-.5-.51Zm1.71,1.24V40.66h-.86c-.09,0-.11,0-.11.11v.95Zm0,.65h-1v1c0,.09,0,.11.11.11h.86Zm.73-.65h1v-.95c0-.09,0-.11-.11-.11h-.92Zm0,.65v1.09h.92c.09,0,.11,0,.11-.11v-1Z"/>
<path className={classes.default} d="M80.56,40.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2H83c.17,0,.27.12.27.34S83.19,41,83,41H81.34v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36H82c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V42h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V41.89c0-.46.1-.56.55-.56h.94V41H78.9c-.18,0-.28-.12-.28-.34s.1-.35.28-.35Z"/>
<path className={classes.default} d="M104.81,31.07c0,.39-.17.42-.49.42l-.06.21h.87c.15,0,.23.1.23.28s-.08.3-.23.3H101c-.15,0-.23-.11-.23-.3s.08-.28.23-.28h.82a.85.85,0,0,0-.07-.21c-.31,0-.44-.07-.44-.42v-.22c0-.32.1-.42.42-.42h2.63c.33,0,.43.1.43.42Zm-3.74-.79c-.14,0-.21-.1-.21-.26s.07-.26.21-.26h4c.13,0,.2.09.2.26s-.07.26-.2.26ZM102.2,28c0-.26.23-.26.32-.26s.33,0,.33.26h.43c0-.18.11-.26.33-.26s.32.08.33.26h.63c.37,0,.47.1.47.47v.63c0,.37-.1.47-.47.47h-3c-.37,0-.47-.1-.47-.47v-.63c0-.37.1-.47.47-.47Zm0,.62v-.18h-.32c-.11,0-.11,0-.11.18Zm0,.35h-.43c0,.16,0,.18.11.18h.32Zm-.1,1.88c-.08,0-.08,0-.08.12s0,.08.08.08H104c.08,0,.08,0,.08-.12s0-.08-.08-.08Zm1.44.83.08-.21h-1.11a1.29,1.29,0,0,1,.07.21Zm-.26-3.06v-.18h-.43v.18Zm0,.35h-.43v.18h.43Zm.66-.35h.43c0-.16,0-.18-.1-.18h-.33Zm0,.35v.18h.33c.1,0,.1,0,.1-.18Z"/>
<path className={classes.default} d="M105.88,28.48c0-.39.11-.5.5-.5h3.2c.4,0,.51.11.51.5v3.19c0,.39-.11.51-.51.51h-3.2c-.39,0-.5-.12-.5-.51Zm1.71,1.24V28.66h-.86c-.09,0-.11,0-.11.11v.95Zm0,.65h-1v1c0,.09,0,.11.11.11h.86Zm.73-.65h1v-.95c0-.09,0-.11-.11-.11h-.92Zm0,.65v1.09h.92c.09,0,.11,0,.11-.11v-1Z"/>
<path className={classes.default} d="M112.56,28.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2H115c.17,0,.27.12.27.34s-.09.35-.27.35h-1.67v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36H114c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V30h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V29.89c0-.46.1-.56.55-.56h.94V29H110.9c-.18,0-.28-.12-.28-.34s.1-.35.28-.35Z"/>
<path className={classes.default} d="M104.81,55.07c0,.39-.17.42-.49.42,0,.06,0,.14-.06.21h.87c.15,0,.23.1.23.28s-.08.3-.23.3H101c-.15,0-.23-.11-.23-.3s.08-.28.23-.28h.82a.85.85,0,0,0-.07-.21c-.31,0-.44-.07-.44-.42v-.22c0-.32.1-.42.42-.42h2.63c.33,0,.43.1.43.42Zm-3.74-.79c-.14,0-.21-.1-.21-.26s.07-.26.21-.26h4c.13,0,.2.09.2.26s-.07.26-.2.26ZM102.2,52c0-.26.23-.26.32-.26s.33,0,.33.26h.43c0-.18.11-.26.33-.26s.32.08.33.26h.63c.37,0,.47.1.47.47v.63c0,.37-.1.47-.47.47h-3c-.37,0-.47-.1-.47-.47v-.63c0-.37.1-.47.47-.47Zm0,.62v-.18h-.32c-.11,0-.11,0-.11.18Zm0,.35h-.43c0,.16,0,.18.11.18h.32Zm-.1,1.88c-.08,0-.08,0-.08.12s0,.08.08.08H104c.08,0,.08,0,.08-.12s0-.08-.08-.08Zm1.44.83.08-.21h-1.11a1.29,1.29,0,0,1,.07.21Zm-.26-3.06v-.18h-.43v.18Zm0,.35h-.43v.18h.43Zm.66-.35h.43c0-.16,0-.18-.1-.18h-.33Zm0,.35v.18h.33c.1,0,.1,0,.1-.18Z"/>
<path className={classes.default} d="M105.88,52.48c0-.39.11-.5.5-.5h3.2c.4,0,.51.11.51.5v3.19c0,.39-.11.51-.51.51h-3.2c-.39,0-.5-.12-.5-.51Zm1.71,1.24V52.66h-.86c-.09,0-.11,0-.11.11v.95Zm0,.65h-1v1c0,.09,0,.11.11.11h.86Zm.73-.65h1v-.95c0-.09,0-.11-.11-.11h-.92Zm0,.65v1.09h.92c.09,0,.11,0,.11-.11v-1Z"/>
<path className={classes.default} d="M112.56,52.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2H115c.17,0,.27.12.27.34s-.09.35-.27.35h-1.67v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36H114c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V54h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V53.89c0-.46.1-.56.55-.56h.94V53H110.9c-.18,0-.28-.12-.28-.34s.1-.35.28-.35Z"/>
<circle className={classes.primary} cx="12" cy="32" r="4"/>
<circle className={classes.primary} cx="44" cy="20" r="4"/>
<circle className={classes.primary} cx="44" cy="44" r="4"/>
<circle className={classes.secondary} cx="108" cy="44" r="4"/>
<circle className={classes.secondary} cx="108" cy="20" r="4"/>
<rect className={classes.default} x="15" y="26.03" width="23.19" height="0.99" transform="matrix(0.94, -0.35, 0.35, 0.94, -7.61, 11)"/><path className={classes.default} d="M40.26,21.4a15,15,0,0,0-3.14,3.7l0-2.51-1.63-1.91A14.74,14.74,0,0,0,40.26,21.4Z"/>
<rect className={classes.default} x="26.1" y="25.88" width="0.99" height="23.19" transform="translate(-17.83 49.22) rotate(-69.44)"/><path className={classes.default} d="M40.26,42.6a15,15,0,0,1-3.14-3.7l0,2.51-1.63,1.91A14.74,14.74,0,0,1,40.26,42.6Z"/>
<rect className={classes.default} x="79" y="26.03" width="23.19" height="0.99" transform="matrix(0.94, -0.35, 0.35, 0.94, -3.55, 33.41)"/><path className={classes.default} d="M104.26,21.4a15,15,0,0,0-3.14,3.7l0-2.51-1.63-1.91A14.74,14.74,0,0,0,104.26,21.4Z"/>
<circle className={classes.primary} cx="76" cy="8" r="4"/>
<rect className={classes.default} x="47" y="14.03" width="23.19" height="0.99" transform="matrix(0.94, -0.35, 0.35, 0.94, -1.38, 21.44)"/><path className={classes.default} d="M72.26,9.4a15,15,0,0,0-3.14,3.7l0-2.51L67.46,8.68A14.74,14.74,0,0,0,72.26,9.4Z"/>
<rect className={classes.default} x="90.1" y="25.88" width="0.99" height="23.19" transform="translate(23.7 109.15) rotate(-69.44)"/><path className={classes.default} d="M104.26,42.6a15,15,0,0,1-3.14-3.7l0,2.51-1.63,1.91A14.74,14.74,0,0,1,104.26,42.6Z"/>
<circle className={classes.secondary} cx="76" cy="32" r="4"/>
<rect className={classes.default} x="58.1" y="13.88" width="0.99" height="23.19" transform="translate(14.17 71.4) rotate(-69.44)"/><path className={classes.default} d="M72.26,30.6a15,15,0,0,1-3.14-3.7l0,2.51-1.63,1.91A14.74,14.74,0,0,1,72.26,30.6Z"/>
<circle className={classes.primary} cx="76" cy="56" r="4"/>
<rect className={classes.default} x="58.1" y="37.88" width="0.99" height="23.19" transform="translate(-8.3 86.97) rotate(-69.44)"/><path className={classes.default} d="M72.26,54.6a15,15,0,0,1-3.14-3.7l0,2.51-1.63,1.91A14.74,14.74,0,0,1,72.26,54.6Z"/>
<path className={classes.default} d="M99.26,78.89a3.35,3.35,0,0,1-.72.22c-.17,0-.32-.2-.32-.45s.08-.25.31-.3a5.72,5.72,0,0,0,1.26-.45l-.13-.12c-.2-.16-.25-.23-.25-.33a.42.42,0,0,1,.4-.37c.15,0,.28.09.6.44a2.86,2.86,0,0,0,.7-.68s0,0,0,0H99.89a3.16,3.16,0,0,1-1.27.78.4.4,0,0,1-.33-.42c0-.15,0-.21.21-.29a3.64,3.64,0,0,0,1.24-1c.13-.16.2-.21.31-.21a.43.43,0,0,1,.42.37.58.58,0,0,1,0,.1h1.28c.28,0,.43.11.43.31a1.26,1.26,0,0,1-.41.76,5.2,5.2,0,0,1-1.07.94h1.42c.36,0,.47.11.47.47v1.15c0,.37-.11.47-.47.47H99.73c-.36,0-.47-.1-.47-.47Zm.86,0c-.08,0-.11,0-.11.11v.58c0,.08,0,.11.11.11h1.59c.08,0,.11,0,.11-.11V79c0-.08,0-.11-.11-.11Z"/>
<path className={classes.default} d="M105.1,76.42v-.36c0-.2.14-.31.39-.31s.39.11.39.31v.36h1.65c.18,0,.28.13.28.36s-.1.35-.28.35h-1.65v.74h.9c.45,0,.56.1.56.56v1.3c0,.46-.11.56-.56.56h-2.56c-.45,0-.56-.1-.56-.56v-1.3c0-.46.11-.56.56-.56h.88v-.74h-1.62c-.19,0-.28-.12-.28-.35s.09-.36.28-.36Zm-.57,2.12c-.09,0-.11,0-.11.11v.85c0,.08,0,.1.11.1h1.94c.09,0,.11,0,.11-.1v-.85c0-.09,0-.11-.11-.11Z"/>
<path className={classes.default} d="M109.22,77.29v.19a8.88,8.88,0,0,1-.27,2.4c-.09.3-.21.41-.39.41a.43.43,0,0,1-.44-.37c0-.08,0-.12.15-.54a6.63,6.63,0,0,0,.2-1.9v-1c0-.43.12-.54.58-.54h3.14c.37,0,.47.1.47.47v.46c0,.36-.1.46-.47.46Zm2.9.7c.15.15.38.43.38.57a.34.34,0,0,1-.33.3c-.12,0-.14,0-.27-.16l-.58,0v.19h1c.15,0,.23.11.23.29s-.08.29-.23.29h-1v.19h1.26c.16,0,.25.1.25.3s-.09.32-.25.32h-3.29c-.16,0-.25-.12-.25-.32s.09-.3.25-.3h1.3v-.19h-1c-.14,0-.23-.1-.23-.29s.09-.29.23-.29h1v-.15l-1,0c-.23,0-.34-.1-.34-.32s.22-.3.4-.3l.15-.21h-.33c-.15,0-.23-.11-.23-.29s.08-.3.23-.3h2.92c.15,0,.23.11.23.3s-.08.29-.23.29Zm-2.9-1.26h2.67c.08,0,.08,0,.08-.15s0-.08-.08-.08H109.3c-.06,0-.08,0-.08.08ZM110.59,78l-.15.22,1.06,0c0-.08,0-.13.1-.2Z"/>
<path className={classes.default} d="M115.11,76.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2h1.67c.17,0,.27.12.27.34s-.09.35-.27.35h-1.67v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36h.19c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V78h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V77.89c0-.46.1-.56.55-.56h.94V77h-1.66c-.18,0-.27-.12-.27-.34s.09-.35.27-.35Z"/>
<path className={classes.default} d="M35.26,30.89a3.35,3.35,0,0,1-.72.22c-.17,0-.32-.2-.32-.45s.08-.25.31-.3a5.72,5.72,0,0,0,1.26-.45l-.13-.12c-.2-.16-.25-.23-.25-.33a.42.42,0,0,1,.4-.37c.15,0,.28.09.6.44a2.86,2.86,0,0,0,.7-.68s0,0,0,0H35.89a3.16,3.16,0,0,1-1.27.78.4.4,0,0,1-.33-.42c0-.15,0-.21.21-.29a3.64,3.64,0,0,0,1.24-.95c.13-.16.2-.21.31-.21a.43.43,0,0,1,.42.37.58.58,0,0,1,0,.1h1.28c.28,0,.43.11.43.31a1.26,1.26,0,0,1-.41.76,5.2,5.2,0,0,1-1.07.94H38.1c.36,0,.47.11.47.47v1.15c0,.37-.11.47-.47.47H35.73c-.36,0-.47-.1-.47-.47Zm.86-.05c-.08,0-.11,0-.11.11v.58c0,.08,0,.11.11.11h1.59c.08,0,.11,0,.11-.11V31c0-.08,0-.11-.11-.11Z"/>
<path className={classes.default} d="M41.1,28.42v-.36c0-.2.14-.31.39-.31s.39.11.39.31v.36h1.65c.18,0,.28.13.28.36s-.1.35-.28.35H41.88v.74h.9c.45,0,.56.1.56.56v1.3c0,.46-.11.56-.56.56H40.22c-.45,0-.56-.1-.56-.56v-1.3c0-.46.11-.56.56-.56h.88v-.74H39.48c-.19,0-.28-.12-.28-.35s.09-.36.28-.36Zm-.57,2.12c-.09,0-.11,0-.11.11v.85c0,.08,0,.1.11.1h1.94c.09,0,.11,0,.11-.1v-.85c0-.09,0-.11-.11-.11Z"/>
<path className={classes.default} d="M45.22,29.29v.19a8.88,8.88,0,0,1-.27,2.4c-.09.3-.21.41-.39.41a.43.43,0,0,1-.44-.37c0-.08,0-.12.15-.54a6.63,6.63,0,0,0,.2-1.9v-1c0-.43.12-.54.58-.54h3.14c.37,0,.47.1.47.47v.46c0,.36-.1.46-.47.46Zm2.9.7c.15.15.38.43.38.57a.34.34,0,0,1-.33.3c-.12,0-.14,0-.27-.16l-.58,0v.19h1c.15,0,.23.11.23.29s-.08.29-.23.29h-1v.19h1.26c.16,0,.25.1.25.3s-.09.32-.25.32H45.29c-.16,0-.25-.12-.25-.32s.09-.3.25-.3h1.3v-.19h-1c-.14,0-.23-.1-.23-.29s.09-.29.23-.29h1v-.15l-1,0c-.23,0-.34-.1-.34-.32s.22-.3.4-.3l.15-.21h-.33c-.15,0-.23-.11-.23-.29s.08-.3.23-.3h2.92c.15,0,.23.11.23.3s-.08.29-.23.29Zm-2.9-1.26h2.67c.08,0,.08,0,.08-.15s0-.08-.08-.08H45.3c-.06,0-.08,0-.08.08ZM46.59,30l-.15.22,1.06,0c0-.08,0-.13.1-.2Z"/>
<path className={classes.default} d="M51.11,28.26v-.2c0-.2.14-.31.39-.31s.39.11.39.31v.2h1.67c.17,0,.27.12.27.34s-.09.35-.27.35H51.89v.38h.94c.45,0,.56.1.56.56v1.48c0,.45-.15.59-.64.59s-.63-.07-.63-.34.08-.36.23-.36h.19c.09,0,.11,0,.11-.18v-1c0-.08,0-.11-.11-.11h-.65v2.13c0,.21-.14.32-.39.32s-.39-.11-.39-.32V30h-.65c-.08,0-.11,0-.11.11v1.54c0,.18-.13.29-.37.29s-.36-.11-.36-.29V29.89c0-.46.1-.56.55-.56h.94V29H49.45c-.18,0-.27-.12-.27-.34s.09-.35.27-.35Z"/>
<circle className={classes.primary} cx="108" cy="68" r="4"/>
<rect className={classes.default} x="90.1" y="49.88" width="0.99" height="23.19" transform="translate(1.23 124.72) rotate(-69.44)"/><path className={classes.default} d="M104.26,66.6a15,15,0,0,1-3.14-3.7l0,2.51-1.63,1.91A14.74,14.74,0,0,1,104.26,66.6Z"/></svg>


      </div>
  )

}

export default InteractiveGraphFig
