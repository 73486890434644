import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: theme.spacing(48) },
  default: { fill: theme.palette.grey[500] },
  primary: { fill: theme.palette.primary.main },
}))

const NetworkVisualizationFig: React.FC = (props) => {
  const classes = useStyles(props)
  return(
      <div  className={classes.root}>

<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 84">
  <circle className={classes.primary} cx="108" cy="56" r="4"/>
<rect className={classes.default} x="90.1" y="37.88" width="0.99" height="23.19" transform="translate(12.46 116.93) rotate(-69.44)"/>
<path className={classes.default} d="M104.26,54.6a15,15,0,0,1-3.14-3.7l0,2.51-1.63,1.91A14.74,14.74,0,0,1,104.26,54.6Z"/>
<path className={classes.default} d="M70,40.5H6A2.5,2.5,0,0,1,3.5,38V6A2.5,2.5,0,0,1,6,3.5H70A2.5,2.5,0,0,1,72.5,6V38A2.5,2.5,0,0,1,70,40.5ZM6,4.5A1.5,1.5,0,0,0,4.5,6V38A1.5,1.5,0,0,0,6,39.5H70A1.5,1.5,0,0,0,71.5,38V6A1.5,1.5,0,0,0,70,4.5Z"/>
<path className={classes.default} d="M9.59,12.25c-.28,0-.46-.12-.46-.35V9.53l-.35.19c-.26.14-.3.14-.42.14a.34.34,0,0,1-.31-.36c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31V11.9C10.07,12.13,9.88,12.25,9.59,12.25Z"/>
<path className={classes.default} d="M14.19,12.24H11.81a.41.41,0,0,1-.44-.47.91.91,0,0,1,.13-.44c.54-.94,1.88-1.26,1.88-1.82a.42.42,0,0,0-.47-.4.86.86,0,0,0-.69.44.35.35,0,0,1-.3.16.44.44,0,0,1-.48-.37c0-.41.58-.87,1.43-.87s1.46.44,1.46,1c0,1.21-1.66,1.28-2,2h1.87c.17,0,.27.13.27.34S14.36,12.24,14.19,12.24Z"/>
<path className={classes.default} d="M16.55,12.3a1.62,1.62,0,0,1-1.25-.4.52.52,0,0,1-.16-.36.45.45,0,0,1,.44-.4c.18,0,.33.16.5.3a1,1,0,0,0,.57.2.47.47,0,0,0,.5-.44c0-.32-.31-.51-.85-.54a.33.33,0,0,1-.33-.35c0-.22.14-.31.36-.34.38-.06.68-.19.68-.5a.37.37,0,0,0-.41-.36.72.72,0,0,0-.47.18.9.9,0,0,1-.54.26.38.38,0,0,1-.39-.38.5.5,0,0,1,.16-.34,1.64,1.64,0,0,1,1.14-.36c.85,0,1.45.31,1.45.93a.86.86,0,0,1-.85.86h0a1,1,0,0,1,1,1C18.11,11.91,17.57,12.3,16.55,12.3Z"/>
<path className={classes.default} d="M20.94,8.84a2.65,2.65,0,0,1-.06.42h.27c.33,0,.45.1.45.39a5,5,0,0,1-1,2.8c-.13.14-.22.19-.33.19a.44.44,0,0,1-.42-.38c0-.09.05-.15.22-.32a2.26,2.26,0,0,0,.54-.79.55.55,0,0,0-.11-.15c-.12-.13-.16-.19-.16-.25a.36.36,0,0,1,0-.11c-.11.15-.17.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.17-.35a3,3,0,0,0,.41-1.08,1.26,1.26,0,0,0,0-.22c-.21,0-.36,0-.38-.31-.08.35-.18.65-.29,1v2.87c0,.18-.12.28-.35.28s-.35-.1-.35-.28V10.55a.44.44,0,0,1-.38-.41c0-.1,0-.13.16-.38a5,5,0,0,0,.44-1.24c.09-.44.11-.56.39-.56s.42.14.41.35a.26.26,0,0,1,.24-.14h1.16c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.32.32,0,0,1,.26-.12.18.18,0,0,1,.14.06,1.75,1.75,0,0,0,.07-.43c0-.09,0-.09-.2-.09A2.69,2.69,0,0,1,20.44,10.48ZM21.7,8.6c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27V12c0,.38,0,.64-.6.64s-.67,0-.67-.33S22,12,22.15,12l.22,0c.08,0,.11,0,.11-.1Z"/>
<path className={classes.default} d="M24,8.79c0-.47.13-.59.6-.59h2.62c.47,0,.59.12.59.59v3.09c0,.46-.12.59-.59.59H24.6c-.47,0-.6-.13-.6-.59Zm3.06.61V9c0-.12,0-.16-.16-.16h-2c-.12,0-.16,0-.16.16V9.4Zm0,.65H24.75v.54h2.31Zm0,1.2H24.75v.38c0,.12,0,.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M9.48,20.25c-.28,0-.46-.12-.46-.35V17.53l-.35.19c-.26.14-.3.14-.42.14a.34.34,0,0,1-.31-.36c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31V19.9C10,20.13,9.77,20.25,9.48,20.25Z"/>
<path className={classes.default} d="M14.08,20.24H11.7a.41.41,0,0,1-.44-.47.91.91,0,0,1,.13-.44c.54-.94,1.88-1.26,1.88-1.82a.42.42,0,0,0-.47-.4.86.86,0,0,0-.69.44.35.35,0,0,1-.3.16.44.44,0,0,1-.48-.37c0-.41.58-.87,1.43-.87s1.46.44,1.46,1c0,1.21-1.66,1.28-2,2h1.87c.17,0,.27.13.27.34S14.25,20.24,14.08,20.24Z"/>
<path className={classes.default} d="M18,19.42h-.25v.47c0,.24-.17.36-.47.36s-.48-.12-.48-.36v-.47H15.41A.41.41,0,0,1,15,19a.46.46,0,0,1,.1-.31l1.34-1.76a.89.89,0,0,1,.75-.46c.45,0,.6.18.6.44v1.87H18c.16,0,.25.11.25.32S18.15,19.42,18,19.42Zm-1.2-2c-.14.23-.27.45-.43.68l-.5.69h.93v-.68c0-.23,0-.48,0-.69Z"/>
<path className={classes.default} d="M21.05,16.84a2.65,2.65,0,0,1-.06.42h.27c.33,0,.45.1.45.39a5.06,5.06,0,0,1-1,2.8c-.14.14-.23.19-.34.19a.44.44,0,0,1-.42-.38c0-.09.05-.15.22-.32a2.4,2.4,0,0,0,.55-.79.64.64,0,0,0-.12-.15c-.12-.13-.16-.19-.16-.25a.24.24,0,0,1,0-.11c-.12.15-.18.2-.28.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.17-.35a3.21,3.21,0,0,0,.42-1.08c0-.14,0-.18,0-.22-.21,0-.36,0-.38-.31-.08.35-.18.65-.29,1v2.87c0,.18-.12.28-.35.28s-.35-.1-.35-.28V18.55a.44.44,0,0,1-.38-.41c0-.1,0-.13.16-.38a5,5,0,0,0,.44-1.24c.09-.44.11-.56.39-.56s.42.14.41.35a.26.26,0,0,1,.24-.14h1.16c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.34.34,0,0,1,.26-.12.15.15,0,0,1,.14.06A1.75,1.75,0,0,0,21,18c0-.09,0-.09-.19-.09A2.6,2.6,0,0,1,20.55,18.48Zm1.26-1.88c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27V20c0,.38,0,.64-.6.64s-.67,0-.67-.33.11-.33.26-.33l.22,0c.08,0,.11,0,.11-.1Z"/>
<path className={classes.default} d="M24.11,16.79c0-.47.13-.59.6-.59h2.62c.47,0,.59.12.59.59v3.09c0,.46-.12.59-.59.59H24.71c-.47,0-.6-.13-.6-.59Zm3.06.61V17c0-.12,0-.16-.16-.16H25c-.12,0-.16,0-.16.16v.37Zm0,.65H24.86v.54h2.31Zm0,1.2H24.86v.38c0,.12,0,.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M9.49,28.25c-.28,0-.46-.12-.46-.35V25.53l-.35.19c-.26.14-.3.14-.42.14A.34.34,0,0,1,8,25.5c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31V27.9C10,28.13,9.78,28.25,9.49,28.25Z"/>
<path className={classes.default} d="M14.09,28.24H11.71a.41.41,0,0,1-.44-.47.91.91,0,0,1,.13-.44c.54-.94,1.87-1.26,1.87-1.82a.41.41,0,0,0-.46-.4.86.86,0,0,0-.69.44.35.35,0,0,1-.3.16.44.44,0,0,1-.48-.37c0-.41.58-.87,1.43-.87s1.46.44,1.46,1c0,1.21-1.66,1.28-2,2h1.87c.17,0,.27.13.27.34S14.26,28.24,14.09,28.24Z"/>
<path className={classes.default} d="M16.58,28.3c-1,0-1.43-.55-1.43-.76s.15-.38.5-.38a.35.35,0,0,1,.29.17.83.83,0,0,0,.66.31.55.55,0,0,0,.59-.56.59.59,0,0,0-.61-.62.92.92,0,0,0-.56.19.74.74,0,0,1-.35.1c-.21,0-.53-.09-.46-.46l.24-1.37c0-.26.19-.38.54-.38h1.67c.19,0,.3.12.3.34s-.1.34-.3.34H16.27c-.06,0-.09,0-.1.07L16,26h0a1.42,1.42,0,0,1,.75-.2A1.23,1.23,0,0,1,18.15,27C18.15,27.82,17.63,28.3,16.58,28.3Z"/>
<path className={classes.default} d="M21,24.84a2.65,2.65,0,0,1-.06.42h.27c.33,0,.45.1.45.39a5,5,0,0,1-1,2.8c-.13.14-.22.19-.33.19a.44.44,0,0,1-.42-.38c0-.09,0-.15.22-.32a2.26,2.26,0,0,0,.54-.79.55.55,0,0,0-.11-.15c-.12-.13-.16-.19-.16-.25a.36.36,0,0,1,0-.11c-.11.15-.17.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.17-.35a3,3,0,0,0,.41-1.08,1.26,1.26,0,0,0,0-.22c-.21,0-.36,0-.38-.31-.08.35-.18.65-.29,1v2.87c0,.18-.12.28-.35.28s-.35-.1-.35-.28V26.55a.44.44,0,0,1-.38-.41c0-.1,0-.13.16-.38a5,5,0,0,0,.44-1.24c.09-.44.11-.56.39-.56s.42.14.41.35a.26.26,0,0,1,.24-.14h1.16c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.34.34,0,0,1,.26-.12.16.16,0,0,1,.14.06A1.75,1.75,0,0,0,21,26c0-.09,0-.09-.2-.09A2.69,2.69,0,0,1,20.54,26.48ZM21.8,24.6c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27V28c0,.38,0,.64-.6.64s-.67,0-.67-.33.11-.33.26-.33l.22,0c.08,0,.11,0,.11-.1Z"/>
<path className={classes.default} d="M24.1,24.79c0-.47.13-.59.6-.59h2.62c.47,0,.59.12.59.59v3.09c0,.46-.12.59-.59.59H24.7c-.47,0-.6-.13-.6-.59Zm3.06.61V25c0-.12,0-.16-.16-.16H25c-.12,0-.16,0-.16.16v.37Zm0,.65H24.85v.54h2.31Zm0,1.2H24.85v.38c0,.12,0,.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M17.79,32.93a.47.47,0,1,1,.47-.47A.47.47,0,0,1,17.79,32.93Zm0,1.67a.47.47,0,1,1,.47-.47A.47.47,0,0,1,17.79,34.6Zm0,1.67a.47.47,0,1,1,.47-.47A.47.47,0,0,1,17.79,36.27Z"/>
<path className={classes.default} d="M36.41,12.25c-.28,0-.46-.12-.46-.35V9.53l-.35.19c-.26.14-.3.14-.42.14a.34.34,0,0,1-.31-.36c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31V11.9C36.89,12.13,36.7,12.25,36.41,12.25Z"/>
<path className={classes.default} d="M39.7,12.31c-1.17,0-1.79-.65-1.79-1.92h0c0-1.22.57-1.91,1.79-1.91s1.78.7,1.78,1.92S40.94,12.31,39.7,12.31Zm0-3.2h0c-.43,0-.82.34-.82,1.28s.32,1.28.83,1.28.82-.37.82-1.28S40.13,9.11,39.7,9.11Z"/>
<path className={classes.default} d="M43.62,12.31c-1.17,0-1.79-.65-1.79-1.92h0c0-1.22.57-1.91,1.79-1.91s1.78.7,1.78,1.92S44.86,12.31,43.62,12.31Zm0-3.2h0c-.43,0-.82.34-.82,1.28s.32,1.28.83,1.28.82-.37.82-1.28S44.05,9.11,43.62,9.11Z"/>
<path className={classes.default} d="M48.06,8.84a2.65,2.65,0,0,1-.06.42h.27c.33,0,.45.1.45.39a5.06,5.06,0,0,1-1,2.8.46.46,0,0,1-.34.19.43.43,0,0,1-.41-.38c0-.09,0-.15.22-.32a2.4,2.4,0,0,0,.54-.79A.92.92,0,0,0,47.6,11c-.13-.13-.16-.19-.16-.25a.2.2,0,0,1,0-.11c-.12.15-.18.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.16-.35a3,3,0,0,0,.42-1.08c0-.14,0-.18,0-.22-.21,0-.36,0-.38-.31a10.21,10.21,0,0,1-.28,1v2.87c0,.18-.13.28-.35.28s-.35-.1-.35-.28V10.55a.44.44,0,0,1-.39-.41c0-.1,0-.13.16-.38a4.33,4.33,0,0,0,.44-1.24c.09-.44.12-.56.4-.56a.37.37,0,0,1,.41.35.23.23,0,0,1,.23-.14h1.16c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.35.35,0,0,1,.26-.12.15.15,0,0,1,.14.06A1.75,1.75,0,0,0,48,10c0-.09,0-.09-.19-.09A2.6,2.6,0,0,1,47.56,10.48ZM48.82,8.6c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27V12c0,.38,0,.64-.6.64s-.67,0-.67-.33.11-.33.26-.33l.22,0c.08,0,.11,0,.11-.1Z"/>
<path className={classes.default} d="M51.13,8.79c0-.47.13-.59.59-.59h2.62c.47,0,.59.12.59.59v3.09c0,.46-.12.59-.59.59H51.72c-.46,0-.59-.13-.59-.59Zm3,.61V9c0-.12,0-.16-.16-.16H52c-.11,0-.16,0-.16.16V9.4Zm0,.65H51.87v.54h2.31Zm0,1.2H51.87v.38c0,.12.05.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M56.26,8.38A.43.43,0,0,1,56.72,8a.32.32,0,0,1,.37.29,9.08,9.08,0,0,0,.36,1.23A5.64,5.64,0,0,1,58.81,9a.41.41,0,0,1,.36.45c0,.25-.07.28-.72.5a8.1,8.1,0,0,0-1.41.67c-.31.21-.44.39-.44.63s.24.45.88.45a8.12,8.12,0,0,0,1-.07,2.71,2.71,0,0,1,.43-.05.37.37,0,0,1,.35.41.38.38,0,0,1-.33.41,9.53,9.53,0,0,1-1.25.09,4.25,4.25,0,0,1-1-.09,1.08,1.08,0,0,1-.89-1.1,1.46,1.46,0,0,1,.72-1.23,1.84,1.84,0,0,1,.25-.17A7.12,7.12,0,0,1,56.26,8.38Z"/>
<path className={classes.default} d="M62.17,10.42a2.77,2.77,0,0,1,.2-.34h-.84c-.16,0-.25-.12-.25-.31s.09-.32.25-.32h.34c0-.12-.1-.32-.17-.51-.15,0-.32,0-.32-.29s.09-.3.24-.3h.79c0-.17,0-.38.37-.38s.37.21.37.38h.9q.24,0,.24.3c0,.29-.16.29-.35.29a5.14,5.14,0,0,1-.16.51h.36c.16,0,.25.11.25.32s-.09.31-.25.31H62.92a.18.18,0,0,1,.07.15.34.34,0,0,1-.05.19h1.22c.16,0,.25.11.25.31s-.09.31-.25.31h-.29a2.12,2.12,0,0,1-.34.69c.71.29.81.36.81.54a.38.38,0,0,1-.33.4,1,1,0,0,1-.45-.17c-.15-.09-.31-.18-.54-.29a4.09,4.09,0,0,1-1.56.48c-.19,0-.3-.14-.3-.37a.28.28,0,0,1,.26-.28,4,4,0,0,0,.76-.18c-.54-.19-.61-.22-.61-.43s0-.13.21-.39h-.25c-.16,0-.25-.11-.25-.32-.05,0-.08.06-.34.18v1.15c0,.45-.18.6-.71.6-.35,0-.46-.09-.46-.39S59.85,12,60,12h.14c.07,0,.1-.05.1-.17v-.58a.68.68,0,0,1-.22,0,.4.4,0,0,1-.34-.43c0-.18,0-.19.56-.39V9.49H60c-.17,0-.26-.12-.26-.33s.09-.33.26-.33h.27V8.27c0-.18.12-.28.35-.28s.35.1.35.28v.56c.22,0,.4,0,.4.33s-.18.33-.4.33v.62l.14,0c.16,0,.29.18.29.39a.25.25,0,0,1,.16-.05Zm.22-1.48c.07.17.14.35.19.51h.47a5.07,5.07,0,0,0,.17-.51Zm.2,2.1-.16.24s0,0,0,.05.27.09.39.14a1.4,1.4,0,0,0,.31-.43Z"/>
<path className={classes.default} d="M66.44,8.27c.23,0,.34.1.34.3s0,.19-.22.58c.4,0,.4.22.4.41V12c0,.48-.17.64-.68.64-.35,0-.47-.09-.47-.35s.09-.33.23-.33h.21c.06,0,.07,0,.07-.14v-.33h-.77a4.24,4.24,0,0,1-.15.79c-.1.29-.19.4-.37.4a.39.39,0,0,1-.39-.36.38.38,0,0,1,0-.18c.26-.58.27-.76.27-2.51q-.3-.12-.3-.36c0-.09,0-.13.15-.27a3.62,3.62,0,0,0,.51-.82c.07-.15.12-.2.24-.2s.44.13.42.31Zm-.56.88A.83.83,0,0,0,66,8.89s0,0,0,0h-.24l-.19.29Zm-.31,1.43c0,.12,0,.29,0,.37h.19v-.37Zm0-.48h.18V9.72c-.15,0-.18,0-.18.11Zm.75,0V9.83c0-.11,0-.11-.17-.11v.38Zm0,.48h-.17V11h.17Zm1.54-1.81V8.24c0-.17.11-.26.33-.26s.32.09.32.26v.53h.27c.44,0,.48.17.48.45V10.5c0,.36-.11.49-.42.49h-.33v.71l.14,0a.92.92,0,0,1-.05-.23.32.32,0,0,1,.35-.27c.17,0,.25,0,.44.87a1.31,1.31,0,0,1,0,.31c0,.28-.32.29-.35.29-.2,0-.23-.11-.27-.38a14.56,14.56,0,0,1-1.46.28.32.32,0,0,1-.3-.36c0-.2.08-.29.28-.3l.53-.07V11h-.25c-.49,0-.49-.2-.49-.47V9.26c0-.27,0-.34.11-.41s.11-.08.4-.08Zm0,.61c-.16,0-.18,0-.18.11v.77c0,.11,0,.11.18.11Zm.82.11c0-.11,0-.11-.19-.11v1c.16,0,.19,0,.19-.11Z"/>
<path className={classes.default} d="M36.41,20.25c-.28,0-.46-.12-.46-.35V17.53l-.35.19c-.26.14-.3.14-.42.14a.34.34,0,0,1-.31-.36c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31V19.9C36.89,20.13,36.7,20.25,36.41,20.25Z"/>
<path className={classes.default} d="M39.7,20.31c-1.17,0-1.79-.65-1.79-1.92h0c0-1.22.57-1.91,1.79-1.91s1.78.7,1.78,1.92S40.94,20.31,39.7,20.31Zm0-3.2h0c-.43,0-.82.34-.82,1.28s.32,1.28.83,1.28.82-.37.82-1.28S40.13,17.11,39.7,17.11Z"/>
<path className={classes.default} d="M43.62,20.31c-1.17,0-1.79-.65-1.79-1.92h0c0-1.22.57-1.91,1.79-1.91s1.78.7,1.78,1.92S44.86,20.31,43.62,20.31Zm0-3.2h0c-.43,0-.82.34-.82,1.28s.32,1.28.83,1.28.82-.37.82-1.28S44.05,17.11,43.62,17.11Z"/>
<path className={classes.default} d="M48.06,16.84a2.65,2.65,0,0,1-.06.42h.27c.33,0,.45.1.45.39a5.06,5.06,0,0,1-1,2.8.46.46,0,0,1-.34.19.43.43,0,0,1-.41-.38c0-.09,0-.15.22-.32a2.4,2.4,0,0,0,.54-.79A.92.92,0,0,0,47.6,19c-.13-.13-.16-.19-.16-.25a.2.2,0,0,1,0-.11c-.12.15-.18.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.16-.35a3,3,0,0,0,.42-1.08c0-.14,0-.18,0-.22-.21,0-.36,0-.38-.31a10.21,10.21,0,0,1-.28,1v2.87c0,.18-.13.28-.35.28s-.35-.1-.35-.28V18.55a.44.44,0,0,1-.39-.41c0-.1,0-.13.16-.38a4.33,4.33,0,0,0,.44-1.24c.09-.44.12-.56.4-.56a.37.37,0,0,1,.41.35.23.23,0,0,1,.23-.14h1.16c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.35.35,0,0,1,.26-.12.15.15,0,0,1,.14.06A1.75,1.75,0,0,0,48,18c0-.09,0-.09-.19-.09A2.6,2.6,0,0,1,47.56,18.48Zm1.26-1.88c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27V20c0,.38,0,.64-.6.64s-.67,0-.67-.33.11-.33.26-.33l.22,0c.08,0,.11,0,.11-.1Z"/>
<path className={classes.default} d="M51.13,16.79c0-.47.13-.59.59-.59h2.62c.47,0,.59.12.59.59v3.09c0,.46-.12.59-.59.59H51.72c-.46,0-.59-.13-.59-.59Zm3,.61V17c0-.12,0-.16-.16-.16H52c-.11,0-.16,0-.16.16v.37Zm0,.65H51.87v.54h2.31Zm0,1.2H51.87v.38c0,.12.05.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M56.26,16.38a.43.43,0,0,1,.46-.36.32.32,0,0,1,.37.29,9.08,9.08,0,0,0,.36,1.23A5.64,5.64,0,0,1,58.81,17a.41.41,0,0,1,.36.45c0,.25-.07.28-.72.5a8.1,8.1,0,0,0-1.41.67c-.31.21-.44.39-.44.63s.24.45.88.45a8.12,8.12,0,0,0,1-.07,2.71,2.71,0,0,1,.43-.05.37.37,0,0,1,.35.41.38.38,0,0,1-.33.41,9.53,9.53,0,0,1-1.25.09,4.25,4.25,0,0,1-1-.09,1.08,1.08,0,0,1-.89-1.1,1.46,1.46,0,0,1,.72-1.23,1.84,1.84,0,0,1,.25-.17A7.12,7.12,0,0,1,56.26,16.38Z"/>
<path className={classes.default} d="M62.17,18.42a2.77,2.77,0,0,1,.2-.34h-.84c-.16,0-.25-.12-.25-.31s.09-.32.25-.32h.34c0-.12-.1-.32-.17-.51-.15,0-.32,0-.32-.29s.09-.3.24-.3h.79c0-.17,0-.38.37-.38s.37.21.37.38h.9q.24,0,.24.3c0,.29-.16.29-.35.29a5.14,5.14,0,0,1-.16.51h.36c.16,0,.25.11.25.32s-.09.31-.25.31H62.92a.18.18,0,0,1,.07.15.34.34,0,0,1-.05.19h1.22c.16,0,.25.11.25.31s-.09.31-.25.31h-.29a2.12,2.12,0,0,1-.34.69c.71.29.81.36.81.54a.38.38,0,0,1-.33.4,1,1,0,0,1-.45-.17c-.15-.09-.31-.18-.54-.29a4.09,4.09,0,0,1-1.56.48c-.19,0-.3-.14-.3-.37a.28.28,0,0,1,.26-.28,4,4,0,0,0,.76-.18c-.54-.19-.61-.22-.61-.43s0-.13.21-.39h-.25c-.16,0-.25-.11-.25-.32-.05.05-.08.06-.34.18v1.15c0,.45-.18.6-.71.6-.35,0-.46-.09-.46-.39S59.85,20,60,20h.14c.07,0,.1-.05.1-.17v-.58a.68.68,0,0,1-.22.05.4.4,0,0,1-.34-.43c0-.18,0-.19.56-.39v-1H60c-.17,0-.26-.12-.26-.33s.09-.33.26-.33h.27v-.56c0-.18.12-.28.35-.28s.35.1.35.28v.56c.22,0,.4,0,.4.33s-.18.33-.4.33v.62l.14,0c.16,0,.29.18.29.39a.25.25,0,0,1,.16,0Zm.22-1.48c.07.17.14.35.19.51h.47a5.07,5.07,0,0,0,.17-.51Zm.2,2.1-.16.24s0,0,0,0,.27.09.39.14a1.4,1.4,0,0,0,.31-.43Z"/>
<path className={classes.default} d="M66.44,16.27c.23,0,.34.1.34.3s0,.19-.22.58c.4,0,.4.22.4.41V20c0,.48-.17.64-.68.64-.35,0-.47-.09-.47-.35s.09-.33.23-.33h.21c.06,0,.07,0,.07-.14v-.33h-.77a4.24,4.24,0,0,1-.15.79c-.1.29-.19.4-.37.4a.39.39,0,0,1-.39-.36.38.38,0,0,1,0-.18c.26-.58.27-.76.27-2.51q-.3-.12-.3-.36c0-.09,0-.13.15-.27a3.62,3.62,0,0,0,.51-.82c.07-.15.12-.2.24-.2s.44.13.42.31Zm-.56.88a.83.83,0,0,0,.12-.26s0,0,0,0h-.24l-.19.29Zm-.31,1.43c0,.12,0,.29,0,.37h.19v-.37Zm0-.48h.18v-.38c-.15,0-.18,0-.18.11Zm.75,0v-.27c0-.11,0-.11-.17-.11v.38Zm0,.48h-.17V19h.17Zm1.54-1.81v-.53c0-.17.11-.26.33-.26s.32.09.32.26v.53h.27c.44,0,.48.17.48.45V18.5c0,.36-.11.49-.42.49h-.33v.71l.14,0a.92.92,0,0,1-.05-.23.32.32,0,0,1,.35-.27c.17,0,.25,0,.44.87a1.31,1.31,0,0,1,0,.31c0,.28-.32.29-.35.29-.2,0-.23-.11-.27-.38a14.56,14.56,0,0,1-1.46.28.32.32,0,0,1-.3-.36c0-.2.08-.29.28-.3l.53-.07V19h-.25c-.49,0-.49-.2-.49-.47V17.26c0-.27,0-.34.11-.41s.11-.08.4-.08Zm0,.61c-.16,0-.18,0-.18.11v.77c0,.11,0,.11.18.11Zm.82.11c0-.11,0-.11-.19-.11v1c.16,0,.19,0,.19-.11Z"/>
<path className={classes.default} d="M36.56,28.25c-.28,0-.46-.12-.46-.35V25.53l-.35.19c-.26.14-.3.14-.42.14A.34.34,0,0,1,35,25.5c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31V27.9C37,28.13,36.85,28.25,36.56,28.25Z"/>
<path className={classes.default} d="M41.16,28.24H38.78a.41.41,0,0,1-.44-.47.91.91,0,0,1,.13-.44c.54-.94,1.88-1.26,1.88-1.82a.42.42,0,0,0-.47-.4.86.86,0,0,0-.69.44.35.35,0,0,1-.3.16.44.44,0,0,1-.48-.37c0-.41.58-.87,1.43-.87s1.46.44,1.46,1c0,1.21-1.66,1.28-2,2h1.87c.17,0,.27.13.27.34S41.33,28.24,41.16,28.24Z"/>
<path className={classes.default} d="M43.52,28.3a1.62,1.62,0,0,1-1.25-.4.52.52,0,0,1-.16-.36.45.45,0,0,1,.44-.4c.18,0,.33.16.5.3a1,1,0,0,0,.57.2.47.47,0,0,0,.5-.44c0-.32-.31-.51-.85-.54a.33.33,0,0,1-.33-.35c0-.22.14-.31.36-.34.38-.06.68-.19.69-.5a.38.38,0,0,0-.42-.36.72.72,0,0,0-.47.18.9.9,0,0,1-.54.26.38.38,0,0,1-.39-.38.5.5,0,0,1,.16-.34,1.64,1.64,0,0,1,1.14-.36c.85,0,1.45.31,1.45.93a.86.86,0,0,1-.84.86h0a1,1,0,0,1,1,.95C45.08,27.91,44.54,28.3,43.52,28.3Z"/>
<path className={classes.default} d="M47.91,24.84a2.65,2.65,0,0,1-.06.42h.27c.33,0,.45.1.45.39a5,5,0,0,1-1,2.8.43.43,0,0,1-.33.19.44.44,0,0,1-.42-.38c0-.09.05-.15.22-.32a2.26,2.26,0,0,0,.54-.79.55.55,0,0,0-.11-.15c-.12-.13-.16-.19-.16-.25a.36.36,0,0,1,0-.11c-.11.15-.17.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.17-.35a3,3,0,0,0,.41-1.08,1.26,1.26,0,0,0,0-.22c-.21,0-.36,0-.38-.31-.08.35-.18.65-.29,1v2.87c0,.18-.12.28-.35.28s-.35-.1-.35-.28V26.55a.44.44,0,0,1-.38-.41c0-.1,0-.13.16-.38a5,5,0,0,0,.44-1.24c.09-.44.11-.56.39-.56s.42.14.41.35a.26.26,0,0,1,.24-.14h1.16c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.34.34,0,0,1,.26-.12.15.15,0,0,1,.14.06,1.75,1.75,0,0,0,.07-.43c0-.09,0-.09-.19-.09A3.14,3.14,0,0,1,47.41,26.48Zm1.26-1.88c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27V28c0,.38,0,.64-.6.64s-.67,0-.67-.33S49,28,49.12,28l.22,0c.08,0,.11,0,.11-.1Z"/>
<path className={classes.default} d="M51,24.79c0-.47.13-.59.6-.59h2.62c.47,0,.59.12.59.59v3.09c0,.46-.12.59-.59.59H51.57c-.47,0-.6-.13-.6-.59ZM54,25.4V25c0-.12,0-.16-.16-.16h-2c-.12,0-.16,0-.16.16v.37Zm0,.65H51.72v.54H54Zm0,1.2H51.72v.38c0,.12,0,.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M56.11,24.38a.43.43,0,0,1,.46-.36.32.32,0,0,1,.37.29,9.08,9.08,0,0,0,.36,1.23A5.64,5.64,0,0,1,58.66,25a.41.41,0,0,1,.36.45c0,.25-.07.28-.72.5a8.1,8.1,0,0,0-1.41.67c-.31.21-.44.39-.44.63s.24.45.88.45a8.12,8.12,0,0,0,1-.07,2.71,2.71,0,0,1,.43-.05.37.37,0,0,1,.35.41.38.38,0,0,1-.33.41,9.53,9.53,0,0,1-1.25.09,4.25,4.25,0,0,1-1-.09,1.07,1.07,0,0,1-.89-1.1,1.44,1.44,0,0,1,.72-1.23,1.37,1.37,0,0,1,.25-.17A7.12,7.12,0,0,1,56.11,24.38Z"/>
<path className={classes.default} d="M62,26.42a2.77,2.77,0,0,1,.2-.34h-.84c-.16,0-.25-.12-.25-.31s.09-.32.25-.32h.34c0-.12-.1-.32-.17-.51-.16,0-.32,0-.32-.29s.08-.3.24-.3h.79c0-.17,0-.38.37-.38s.37.21.37.38h.9q.24,0,.24.3c0,.29-.16.29-.35.29a5.14,5.14,0,0,1-.16.51H64c.16,0,.25.11.25.32s-.09.31-.25.31H62.77a.18.18,0,0,1,.07.15.34.34,0,0,1-.05.19H64c.16,0,.25.11.25.31S64.17,27,64,27h-.29a2.12,2.12,0,0,1-.34.69c.71.29.81.36.81.54a.38.38,0,0,1-.33.4,1,1,0,0,1-.45-.17c-.15-.09-.31-.18-.54-.29a4.14,4.14,0,0,1-1.56.48c-.19,0-.31-.14-.31-.37a.28.28,0,0,1,.26-.28,4,4,0,0,0,.77-.18c-.55-.19-.61-.22-.61-.43s0-.13.2-.39h-.24c-.17,0-.25-.11-.25-.32a1.55,1.55,0,0,1-.34.18v1.15c0,.45-.18.6-.71.6-.36,0-.46-.09-.46-.39s.08-.31.22-.31H60c.08,0,.1-.05.1-.17v-.58a.63.63,0,0,1-.22.05.4.4,0,0,1-.33-.43c0-.18,0-.19.55-.39v-1h-.26c-.17,0-.27-.12-.27-.33s.1-.33.27-.33h.26v-.56c0-.18.13-.28.35-.28s.36.1.36.28v.56c.21,0,.4,0,.4.33s-.19.33-.4.33v.62l.13,0a.36.36,0,0,1,.3.39.25.25,0,0,1,.16,0Zm.22-1.48c.07.17.14.35.19.51h.47a5.07,5.07,0,0,0,.17-.51Zm.2,2.1-.16.24s0,0,0,0,.27.09.39.14A1.4,1.4,0,0,0,63,27Z"/>
<path className={classes.default} d="M66.28,24.27c.23,0,.34.1.34.3s0,.19-.21.58c.4,0,.4.22.4.41V28c0,.48-.18.64-.68.64-.35,0-.47-.09-.47-.35s.09-.33.22-.33h.21c.06,0,.08,0,.08-.14v-.33h-.78a3.56,3.56,0,0,1-.15.79c-.09.29-.19.4-.36.4a.39.39,0,0,1-.39-.36.38.38,0,0,1,.05-.18c.25-.58.26-.76.26-2.51a.39.39,0,0,1-.29-.36c0-.09,0-.13.15-.27a3.3,3.3,0,0,0,.5-.82c.07-.15.13-.2.24-.2s.44.13.43.31Zm-.55.88a1,1,0,0,0,.11-.26s0,0,0,0h-.25l-.18.29Zm-.32,1.43V27h.18v-.37Zm0-.48h.17v-.38c-.15,0-.17,0-.17.11Zm.75,0v-.27c0-.11,0-.11-.17-.11v.38Zm0,.48H66V27h.17Zm1.54-1.81v-.53c0-.17.11-.26.33-.26s.32.09.32.26v.53h.27c.44,0,.48.17.48.45V26.5c0,.36-.11.49-.42.49h-.33v.71l.14,0a.92.92,0,0,1,0-.23.32.32,0,0,1,.35-.27c.17,0,.25,0,.44.87a1.31,1.31,0,0,1,0,.31c0,.28-.32.29-.35.29-.2,0-.23-.11-.27-.38a14.56,14.56,0,0,1-1.46.28.32.32,0,0,1-.3-.36c0-.2.08-.29.28-.3l.53-.07V27h-.25c-.49,0-.49-.2-.49-.47V25.26c0-.27,0-.34.11-.41s.11-.08.4-.08Zm0,.61c-.16,0-.18,0-.18.11v.77c0,.11,0,.11.18.11Zm.82.11c0-.11,0-.11-.19-.11v1c.16,0,.19,0,.19-.11Z"/>
<path className={classes.default} d="M51.79,32.93a.47.47,0,1,1,.47-.47A.47.47,0,0,1,51.79,32.93Zm0,1.67a.47.47,0,1,1,.47-.47A.47.47,0,0,1,51.79,34.6Zm0,1.67a.47.47,0,1,1,.47-.47A.47.47,0,0,1,51.79,36.27Z"/>
<rect className={classes.default} x="31.5" y="4" width="0.99" height="36"/>
<path className={classes.default} d="M35.44,68c-.28,0-.46-.13-.46-.36V65.29l-.35.19c-.26.14-.3.14-.42.14a.34.34,0,0,1-.31-.36c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31v3.12C35.92,67.89,35.73,68,35.44,68Z"/>
<path className={classes.default} d="M38.73,68.07c-1.17,0-1.79-.65-1.79-1.92h0c0-1.22.57-1.91,1.79-1.91s1.78.7,1.78,1.92S40,68.07,38.73,68.07Zm0-3.2h0c-.43,0-.82.34-.82,1.28s.32,1.28.83,1.28.82-.37.82-1.28S39.16,64.87,38.73,64.87Z"/>
<path className={classes.default} d="M42.65,68.07c-1.17,0-1.79-.65-1.79-1.92h0c0-1.22.57-1.91,1.79-1.91s1.78.7,1.78,1.92S43.89,68.07,42.65,68.07Zm0-3.2h0c-.43,0-.82.34-.82,1.28s.32,1.28.83,1.28.82-.37.82-1.28S43.08,64.87,42.65,64.87Z"/>
<path className={classes.default} d="M47.09,64.6A2.65,2.65,0,0,1,47,65h.27c.33,0,.45.1.45.39a5,5,0,0,1-1,2.8.48.48,0,0,1-.34.2A.44.44,0,0,1,46,68c0-.09,0-.15.22-.31a2.59,2.59,0,0,0,.54-.8.92.92,0,0,0-.11-.15c-.13-.13-.16-.19-.16-.25a.2.2,0,0,1,0-.11c-.12.15-.18.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.16-.35a3,3,0,0,0,.42-1.08c0-.14,0-.18,0-.22-.21,0-.36,0-.38-.31a10.21,10.21,0,0,1-.28,1v2.87c0,.18-.13.28-.35.28s-.35-.1-.35-.28V66.31a.44.44,0,0,1-.39-.41c0-.1,0-.13.17-.38a4.59,4.59,0,0,0,.43-1.24c.09-.44.12-.56.4-.56a.37.37,0,0,1,.41.35.23.23,0,0,1,.23-.14H47.5c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.35.35,0,0,1,.26-.12.15.15,0,0,1,.14.06,1.75,1.75,0,0,0,.07-.43c0-.09,0-.09-.19-.09A2.6,2.6,0,0,1,46.59,66.24Zm1.26-1.88c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27v3.73c0,.38,0,.64-.6.64s-.67,0-.67-.33.11-.33.26-.33l.22,0c.08,0,.11,0,.11-.11Z"/>
<path className={classes.default} d="M50.16,64.55c0-.47.13-.59.59-.59h2.62c.47,0,.59.12.59.59v3.08c0,.47-.12.6-.59.6H50.75c-.46,0-.59-.13-.59-.6Zm3.05.61v-.37c0-.12,0-.16-.16-.16h-2c-.11,0-.16,0-.16.16v.37Zm0,.65H50.9v.54h2.31Zm0,1.2H50.9v.38c0,.12.05.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M67.59,56c-.28,0-.46-.13-.46-.36V53.29l-.35.19c-.26.14-.3.14-.42.14a.34.34,0,0,1-.31-.36c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31v3.12C68.07,55.89,67.88,56,67.59,56Z"/>
<path className={classes.default} d="M72.19,56H69.81a.41.41,0,0,1-.44-.46.92.92,0,0,1,.13-.45c.54-.94,1.88-1.26,1.88-1.82a.42.42,0,0,0-.47-.4.86.86,0,0,0-.69.44.35.35,0,0,1-.3.16.44.44,0,0,1-.48-.37c0-.41.58-.87,1.43-.87s1.46.44,1.46,1c0,1.21-1.66,1.28-2,2h1.87c.17,0,.27.13.27.34S72.36,56,72.19,56Z"/>
<path className={classes.default} d="M74.55,56.06a1.62,1.62,0,0,1-1.25-.4.52.52,0,0,1-.16-.36.45.45,0,0,1,.44-.4c.18,0,.33.16.5.3a1,1,0,0,0,.57.2.46.46,0,0,0,.49-.44c0-.32-.3-.51-.84-.54a.33.33,0,0,1-.33-.35c0-.22.14-.31.36-.34.38-.06.68-.19.68-.5a.37.37,0,0,0-.41-.36.72.72,0,0,0-.47.18.9.9,0,0,1-.54.26.38.38,0,0,1-.39-.38.5.5,0,0,1,.16-.34,1.64,1.64,0,0,1,1.14-.36c.85,0,1.45.31,1.45.93a.86.86,0,0,1-.85.86h0a1,1,0,0,1,1,.95C76.11,55.67,75.57,56.06,74.55,56.06Z"/>
<path className={classes.default} d="M78.94,52.6a2.65,2.65,0,0,1-.06.42h.27c.33,0,.45.1.45.39a5,5,0,0,1-1,2.8c-.13.14-.22.2-.33.2a.45.45,0,0,1-.42-.39c0-.09.05-.15.22-.31a2.43,2.43,0,0,0,.54-.8.55.55,0,0,0-.11-.15c-.12-.13-.16-.19-.16-.25a.36.36,0,0,1,0-.11c-.11.15-.17.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.17-.35a3,3,0,0,0,.41-1.08,1.26,1.26,0,0,0,0-.22c-.21,0-.36,0-.38-.31-.08.35-.18.65-.29,1v2.87c0,.18-.12.28-.35.28s-.35-.1-.35-.28V54.31a.44.44,0,0,1-.38-.41c0-.1,0-.13.16-.38a5,5,0,0,0,.44-1.24c.09-.44.11-.56.39-.56s.42.14.41.35a.26.26,0,0,1,.24-.14h1.16c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.32.32,0,0,1,.26-.12.18.18,0,0,1,.14.06,2.4,2.4,0,0,0,.07-.43c0-.09,0-.09-.2-.09A2.69,2.69,0,0,1,78.44,54.24Zm1.26-1.88c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27v3.73c0,.38,0,.64-.6.64s-.67,0-.67-.33.11-.33.26-.33l.22,0c.08,0,.11,0,.11-.11Z"/>
<path className={classes.default} d="M82,52.55c0-.47.13-.59.6-.59h2.62c.47,0,.59.12.59.59v3.08c0,.47-.12.6-.59.6H82.6c-.47,0-.6-.13-.6-.6Zm3.06.61v-.37c0-.12,0-.16-.16-.16h-2c-.12,0-.16,0-.16.16v.37Zm0,.65H82.75v.54h2.31Zm0,1.2H82.75v.38c0,.12,0,.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M67.48,80c-.28,0-.46-.13-.46-.36V77.29l-.35.19c-.26.14-.3.14-.42.14a.34.34,0,0,1-.31-.36c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31v3.12C68,79.89,67.77,80,67.48,80Z"/>
<path className={classes.default} d="M72.08,80H69.7a.41.41,0,0,1-.44-.46.92.92,0,0,1,.13-.45c.54-.94,1.88-1.26,1.88-1.82a.42.42,0,0,0-.47-.4.86.86,0,0,0-.69.44.35.35,0,0,1-.3.16.44.44,0,0,1-.48-.37c0-.41.58-.87,1.43-.87s1.46.44,1.46,1c0,1.21-1.66,1.28-2,2h1.87c.17,0,.27.13.27.34S72.25,80,72.08,80Z"/>
<path className={classes.default} d="M76,79.18h-.25v.47c0,.24-.17.37-.48.37s-.47-.13-.47-.37v-.47H73.41a.41.41,0,0,1-.46-.42.46.46,0,0,1,.1-.31l1.34-1.76a.89.89,0,0,1,.75-.46c.44,0,.6.18.6.44v1.87H76c.16,0,.25.11.25.32S76.15,79.18,76,79.18Zm-1.21-2c-.13.23-.26.45-.42.68l-.5.69h.93v-.68c0-.23,0-.48,0-.69Z"/>
<path className={classes.default} d="M79.05,76.6A2.65,2.65,0,0,1,79,77h.27c.33,0,.45.1.45.39a5,5,0,0,1-1,2.8c-.13.14-.22.2-.33.2a.45.45,0,0,1-.42-.39c0-.09,0-.15.22-.31a2.43,2.43,0,0,0,.54-.8.55.55,0,0,0-.11-.15c-.12-.13-.16-.19-.16-.25a.36.36,0,0,1,0-.11c-.11.15-.17.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.17-.35a3,3,0,0,0,.41-1.08,1.26,1.26,0,0,0,0-.22c-.21,0-.36,0-.38-.31-.08.35-.18.65-.29,1v2.87c0,.18-.12.28-.35.28s-.35-.1-.35-.28V78.31a.44.44,0,0,1-.38-.41c0-.1,0-.13.16-.38a5,5,0,0,0,.44-1.24c.09-.44.11-.56.39-.56s.42.14.41.35a.26.26,0,0,1,.24-.14h1.16c.18,0,.27.12.27.34s-.09.33-.27.33Zm-.5,1.64a.32.32,0,0,1,.26-.12.18.18,0,0,1,.14.06,2.4,2.4,0,0,0,.07-.43c0-.09,0-.09-.2-.09A2.69,2.69,0,0,1,78.55,78.24Zm1.26-1.88c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27v3.73c0,.38,0,.64-.6.64s-.67,0-.67-.33.11-.33.26-.33l.22,0c.08,0,.11,0,.11-.11Z"/>
<path className={classes.default} d="M82.11,76.55c0-.47.13-.59.6-.59h2.62c.47,0,.59.12.59.59v3.08c0,.47-.12.6-.59.6H82.71c-.47,0-.6-.13-.6-.6Zm3.06.61v-.37c0-.12,0-.16-.16-.16H83c-.12,0-.16,0-.16.16v.37Zm0,.65H82.86v.54h2.31Zm0,1.2H82.86v.38c0,.12,0,.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<path className={classes.default} d="M99.49,68c-.28,0-.46-.13-.46-.36V65.29l-.35.19c-.26.14-.3.14-.42.14a.34.34,0,0,1-.31-.36c0-.22.12-.31.32-.42l1-.53a.54.54,0,0,1,.3-.08c.26,0,.4.12.4.31v3.12C100,67.89,99.78,68,99.49,68Z"/>
<path className={classes.default} d="M104.09,68h-2.38a.41.41,0,0,1-.44-.46.92.92,0,0,1,.13-.45c.54-.94,1.87-1.26,1.87-1.82a.41.41,0,0,0-.46-.4.86.86,0,0,0-.69.44.35.35,0,0,1-.3.16.44.44,0,0,1-.48-.37c0-.41.58-.87,1.43-.87s1.46.44,1.46,1c0,1.21-1.66,1.28-2,2h1.87c.17,0,.27.13.27.34S104.26,68,104.09,68Z"/>
<path className={classes.default} d="M106.58,68.06c-1,0-1.43-.55-1.43-.76s.15-.38.5-.38a.35.35,0,0,1,.29.17.83.83,0,0,0,.66.31.55.55,0,0,0,.58-.56.58.58,0,0,0-.6-.62.92.92,0,0,0-.56.19.74.74,0,0,1-.35.1c-.21,0-.53-.09-.46-.46l.24-1.37c0-.26.19-.38.54-.38h1.67c.19,0,.3.12.3.34s-.1.34-.3.34h-1.39c-.06,0-.09,0-.1.07l-.13.73h0a1.42,1.42,0,0,1,.75-.2,1.23,1.23,0,0,1,1.34,1.21C108.15,67.58,107.62,68.06,106.58,68.06Z"/>
<path className={classes.default} d="M111,64.6A2.65,2.65,0,0,1,111,65h.27c.33,0,.45.1.45.39a5,5,0,0,1-1,2.8c-.13.14-.22.2-.33.2a.45.45,0,0,1-.42-.39c0-.09,0-.15.22-.31a2.43,2.43,0,0,0,.54-.8.55.55,0,0,0-.11-.15c-.12-.13-.16-.19-.16-.25a.36.36,0,0,1,0-.11c-.11.15-.17.2-.27.2a.42.42,0,0,1-.39-.35c0-.09,0-.14.17-.35a3,3,0,0,0,.41-1.08,1.26,1.26,0,0,0,0-.22c-.21,0-.36,0-.38-.31-.08.35-.18.65-.29,1v2.87c0,.18-.12.28-.35.28s-.35-.1-.35-.28V66.31a.44.44,0,0,1-.38-.41c0-.1,0-.13.16-.38a5,5,0,0,0,.44-1.24c.09-.44.11-.56.39-.56s.42.14.41.35a.26.26,0,0,1,.24-.14h1.16c.17,0,.27.12.27.34s-.1.33-.27.33Zm-.5,1.64a.32.32,0,0,1,.26-.12.18.18,0,0,1,.14.06,2.4,2.4,0,0,0,.07-.43c0-.09,0-.09-.2-.09A2.69,2.69,0,0,1,110.54,66.24Zm1.26-1.88c0-.15.11-.23.3-.23s.29.08.29.23v2.7c0,.15-.1.24-.29.24s-.3-.09-.3-.24Zm.78-.35c0-.17.13-.27.34-.27s.34.1.34.27v3.73c0,.38,0,.64-.6.64s-.67,0-.67-.33.11-.33.26-.33l.22,0c.08,0,.11,0,.11-.11Z"/>
<path className={classes.default} d="M114.1,64.55c0-.47.13-.59.6-.59h2.62c.47,0,.59.12.59.59v3.08c0,.47-.12.6-.59.6H114.7c-.47,0-.6-.13-.6-.6Zm3.06.61v-.37c0-.12,0-.16-.16-.16h-2c-.12,0-.16,0-.16.16v.37Zm0,.65h-2.31v.54h2.31Zm0,1.2h-2.31v.38c0,.12,0,.16.16.16h2c.12,0,.16,0,.16-.16Z"/>
<polygon className={classes.default} points="32.99 56.92 15 56.48 15 44 16 44 16 55.52 33.02 55.93 32.99 56.92"/>
<path className={classes.default} d="M36,56.5a15.08,15.08,0,0,0-4.3,2.26l.91-2.34L31.82,54A15,15,0,0,0,36,56.5Z"/>
<circle className={classes.primary} cx="44" cy="56" r="4"/>
<circle className={classes.primary} cx="76" cy="44" r="4"/>
<circle className={classes.primary} cx="76" cy="68" r="4"/>
<rect className={classes.default} x="47" y="50.03" width="23.19" height="0.99" transform="translate(-14.01 23.79) rotate(-20.56)"/>
<path className={classes.default} d="M72.26,45.4a15,15,0,0,0-3.14,3.7l0-2.51-1.63-1.91A14.74,14.74,0,0,0,72.26,45.4Z"/>
<rect className={classes.default} x="58.1" y="49.88" width="0.99" height="23.19" transform="translate(-19.54 94.76) rotate(-69.44)"/>
<path className={classes.default} d="M72.26,66.6a15,15,0,0,1-3.14-3.7l0,2.51-1.63,1.91A14.74,14.74,0,0,1,72.26,66.6Z"/>
</svg>


      </div>
  )

}

export default NetworkVisualizationFig
